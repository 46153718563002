<template>
  <li v-if="isStandardItem" class="pro_cart_item bg-grey-11 relative clearfix">
    <div class="col pro_cart_img float-left">
      <product-image :product="product"/>
    </div>
    <div class="col pro_cart_desc px-5 float-left">
      <span class="pro_cart_desc_name text-dark text-h4 block leading-base">
        <span class="block" v-if="configurableProductInfo">{{ configurableProductInfo.label | stripHTML }}: {{ configurableProductInfo.value | stripHTML }}</span>
        <span class="block">{{ product.name | htmlDecode }}</span>
      </span>
      <span>
        <span v-if="!getRulePrice(product)">
          <product-price
            :product="product"
            :is-total="true"
          />
        </span>
        <span v-else class="text-sm font-semibold">
          {{ getRulePrice(product) | price }}
        </span>
      </span>
    </div>
    <div class="col pro_cart_qty relative float-left inline-block text-right">
        <span class="text-center leadiing-normal block text-h4">
          {{ getQuantity }}
        </span>
    </div>
  </li>
</template>

<script>
import ProductImage from 'theme/components/theme/blocks/Checkout/ProductImage'
import ProductPrice from 'theme/components/theme/ProductPrice'
import Product from 'theme/mixins/blocks/Product'

export default {
  name: 'SidebarProduct',
  components: {
    ProductImage,
    ProductPrice
  },
  mixins: [Product],
  props: {
    product: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
$grey-1: #777;
$grey-2: #d0d0d0;

.pro_cart_item {
  border-bottom: solid 1px $grey-2;
  min-height: 70px;
  padding: 23px 20px;

  &:last-child {
     border-bottom: none;
   }
}

.pro_cart_img {
  width: 50px;
}

.pro_cart_desc {
  width: 180px;
}

.pro_cart_qty {
  width: 30px;
}
</style>
