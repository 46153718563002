<template>
  <div>
    <div class="px-7 pb-7 pt-11">
      <h2 class="pro_checkout_sp mb-sm font-serif font-black text-h2 leading-none text-dark capitalize">
        {{ $t('Order Summary') }}
      </h2>
    </div>
    <div class="pro_cart_view">
      <div v-for="(brand, key) in groupedProducts" :key="key">
        <div v-if="brand.length && points">
          <span
            class="pro_cart_brand block relative bg-black text-h5 font-bold leading-normal tracking-average uppercase text-white"
            :class="[
              points[key] && points[key]['balance'] ? key : `${key} contentless`,
              isActiveTab(key) ? 'active' : ''
            ]"
            @click="toggleLoyaltyInfo(key)"
          >
            <i class="ss-gizmo ss-plus inline-block text-heading-page absolute" v-if="points[key] && (points[key]['balance'] || points[key]['earnedPoints'])"></i>
            <span class="block w-full uppercase leading-normal text-p4 font-bold">
              <span>{{ brandTitles[key] }}</span>
              <span>{{ `(${getProductsQty(brand)})` }}</span>
              <span class="text-h5 leading-normal tracking-md float-right pr-7 font-normal uppercase"> &diams; Earns</span>
            </span>
            <span class="block w-full uppercase leading-normal text-p4 clearfix">
              <span class="inline-block pr-sm float-left font-bold">
                {{ brandSubtotal[key] | price }}
              </span>
              <span
                v-if="points[key] && points[key]['earnedPoints']"
                class="text-h5 leading-normal tracking-md float-right pr-7 font-normal uppercase"
              >
                {{ `${points[key]['earnedPoints'].toFixed(2)} ` + $t('Points') }}
              </span>
            </span>
          </span>
          <div
            class="pro_onepage_pts pro_v76-by-vaughn bg-black text-white pl-7 font-bold text-sm leading-none uppercase overflow-hidden tracking-md"
            v-if="points[key] && (points[key]['balance'] || points[key]['earnedPoints'])"
          >
          <span class="add_table_title font-bold leading-none text-h5 block uppercase">
            {{ $t('Loyalty Points') }}
          </span>
            <table class="block w-full">
              <thead>
              <tr>
                <th class="w-1/4 text-left">
                  {{ $t('Current') }}
                  <br>
                  {{ $t('Balance') }}
                </th>
                <th class="w-1/4 text-left">
                  {{ $t('Earned') }}
                  <br>
                  {{ $t('Points') }}
                </th>
                <th class="w-1/4 text-left">
                  {{ $t('Redeemed') }}
                  <br>
                  {{ $t('Points') }}
                </th>
                <th class="w-1/4 text-left">
                  {{ $t('New') }}
                  <br>
                  {{ $t('Balance') }}
                </th>
              </tr>
              </thead>
              <tbody>
              <td class="font-bold text-h5 leading-none">
                {{ points[key]['balance'].toFixed(2) }}
              </td>
              <td class="font-bold text-h5 leading-none">
                {{ points[key]['earnedPoints'].toFixed(2) }}
              </td>
              <td class="font-bold text-h5 leading-none">
                {{ getBrandRedeemedPoints(points[key]['id']).toFixed(2) }}
              </td>
              <td class="font-bold text-h5 leading-none">
                {{ (points[key]['balance'] + points[key]['earnedPoints'] - getBrandRedeemedPoints(points[key]['id'])).toFixed(2) }}
              </td>
              </tbody>
            </table>
          </div>
          <ul class="pro_cart_list relative">
            <template v-for="(product, index) in brand">
              <sidebar-product :product="product" :key="index"/>
              <sidebar-product-loyalty :product="product" :key="index + 'loyalty'"/>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cart from 'theme/mixins/pages/Cart'
import EarnedPoints from 'theme/mixins/blocks/EarnedPoints'
import PriceRules from 'theme/mixins/blocks/PriceRules'
import SidebarProduct from 'theme/components/theme/blocks/Checkout/SidebarProduct'
import SidebarProductLoyalty from 'theme/components/theme/blocks/Checkout/SidebarProductLoyalty'
import {mapGetters} from 'vuex'

export default {
  name: 'SummarySidebar',
  components: {
    SidebarProductLoyalty,
    SidebarProduct
  },
  mixins: [
    Cart,
    EarnedPoints,
    PriceRules
  ],
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      affix: false,
      activeTabs: [],
      brandTitles: {
        rco: 'R+Co',
        rco_bleu: 'R+Co Bleu',
        rco_color: 'R+Co Color',
        v76: 'V76',
        sc: 'Smith \& Cult',
        elz: 'Elaluz'
      },
      points: null,
      redeemPoints: 0
    }
  },
  async beforeMount () {
    this.points = await this.getRewards()
  },
  computed: {
    ...mapGetters({
      loyaltyProductsInCart: 'cart/getLoyaltyCartItems'
    }),
    brandSubtotal () {
      let brandProducts = this.groupedProducts
      let brandSubtotal = {}

      for (let key in brandProducts) {
        let products = brandProducts[key]

        brandSubtotal[key] = 0

        products.forEach(product => {
          if (product.point_redemption_price) return

          let rulePrice = this.getRulePrice(product)

          if (rulePrice) {
            brandSubtotal[key] += rulePrice * product.qty
          } else {
            brandSubtotal[key] += product.price * product.qty
          }
        })
      }

      return brandSubtotal
    },
    redeemedProductsPoints () {
      let cartItems = this.loyaltyProductsInCart
      let redeemProducts = {}

      cartItems.forEach(item => {
        let price = this.getRulePrice(item)
        let qty = ((item.product_option || {}).extension_attributes || {}).qty_using_points || 0

        price = (item.point_spending_multiplier || 1) * price
        price = qty * price

        if (!redeemProducts[item.brand_id]) {
          redeemProducts[item.brand_id] = {
            redeemedPoints: price
          }
        } else {
          redeemProducts[item.brand_id]['redeemedPoints'] += price
        }
      })

      return redeemProducts
    }
  },
  methods: {
    isActiveTab (id) {
      return this.activeTabs.includes(id)
    },
    toggleLoyaltyInfo (id) {
      let index = this.activeTabs.indexOf(id)

      if (this.isActiveTab(id)) {
        this.activeTabs.splice(index, 1)
      } else {
        this.activeTabs.push(id)
      }
    },
    getProductsQty (brandProducts) {
      let qty = 0

      brandProducts.forEach(product => {
        qty += product.qty
      })

      return qty
    },
    getBrandRedeemedPoints (brandId) {
      let points = 0

      if (this.redeemedProductsPoints[brandId]) points = this.redeemedProductsPoints[brandId]['redeemedPoints']

      return points
    }
  }
}
</script>

<style lang="scss" scoped>
  $grey-1: #777;
  $grey-2: #d0d0d0;

  .pro_cart_view {
    .pro_cart_brand {
      padding: 13px 0 13px 58px;

      &.active {
        + .pro_onepage_pts {
          display: block;
        }
      }

      &.contentless {
        padding-left: 20px;
      }

      &:not(.contentless) {
        @apply cursor-pointer;
      }

      &:not(.active) {
        &:after {
          @apply w-full block absolute bg-black;
          content: '';
          height: 2px;
          bottom: 0;
          left: 0;
          transition: background-color .5s ease;
        }

        &.v76 {
          &:hover {
            &:after {
              @apply bg-yellow;
            }
          }
        }

        &.rco {
          &:hover {
            &:after {
              @apply bg-green;
            }
          }
        }

        &.sc {
          &:hover {
            &:after {
              @apply bg-red;
            }
          }
        }
      }

      i {
        top: 26px;
        left: 25px;
      }
    }

    .pro_onepage_pts {
      display: none;
      height: 89px;
      transition: height .5s ease;

      table {
        padding-bottom: 13px;

        th {
          padding: 0 10px 6.5px 0;
          color: $grey-1;
        }

        td {

        }
      }
    }

    .add_table_title {
      padding-bottom: 13px;
      padding-top: 13px;
    }

    .pro_cart_item {
      border-bottom: solid 1px $grey-2;
      min-height: 70px;
      padding: 23px 20px;

      &:last-child {
        border-bottom: none;
      }
    }

    .pro_cart_img {
      width: 50px;
    }

    .pro_cart_desc {
      width: 180px;
    }

    .pro_cart_qty {
      width: 30px;
    }
  }
</style>
