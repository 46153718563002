<template>
  <section id="checkout-success" class="page-container mx-auto px-18 py-15 relative text-center">
    <no-ssr>
      <div class="wrapper">
        <div class="uppercase font-bold text-h5 leading-h2 tracking-xl">
          {{ `${$t('Thank you for your purchase')}, ${checkoutBillingFirstName}!` }}
        </div>
        <div class="font-black text-caption leading-h2 text-black font-serif mb-10">
          {{ $t('Your order has been received.') }}
        </div>
        <div class="text-h6 leading-none tracking-mid uppercase mb-md">
          {{ $t('ORDER NUMBER') }}
        </div>
        <div class="mx-auto bg-white border border-solid border-black px-10 py-4 text-center text-h5 uppercase tracking-sm md:w-1/3 mb-10 text-black flex justify-center items-center">
          <span>
            {{ lastOrderConfirmation.orderNumber }}
          </span>
        </div>
        <div class="info mx-auto mb-md">
          {{ $t('You will receive an order confirmation email with details of your order and a link to track its progress.') }}
        </div>
        <router-link
          :to="localizedRoute({name: 'home'})"
          class="relative block mx-auto slow-color-change whitespace-no-wrap outline-none focus:outline-none rounded-0 bg-black hover:bg-primary2 border-0 text-white px-10 py-4 text-center text-h5 uppercase tracking-sm md:w-1/3 mb-md"
        >
          <span class="text-base">{{ $t('Continue') }}</span>
          <i class="icon-home absolute ss-gizmo ss-home text-h3"></i>
        </router-link>
      </div>
    </no-ssr>
  </section>
</template>

<script>
import NoSSR from 'vue-no-ssr'
import ButtonFull from 'theme/components/theme/ButtonFull'

export default {
  components: {
    'no-ssr': NoSSR,
    ButtonFull
  },
  mounted () {
    if (!this.$isServer) {
      window.scrollTo(0, 0)
    }
  },
  computed: {
    lastOrderConfirmation () {
      return this.$store.state.order.last_order_confirmation ? this.$store.state.order.last_order_confirmation.confirmation : {}
    },
    checkoutBillingFirstName () {
      return this.$store.state.checkout.paymentDetails.firstName
    }
  },
  destroyed () {
    this.$store.dispatch('checkout/setThankYouPage', false)
  }
}
</script>

<style lang="scss" scoped>
  .page-container {
    @screen lg {
      width: 1030px;
    }

    &:before {
      @screen lg {
        top: 30px;
        right: 120px;
      }
    }

    &:after {
      @screen lg {
        left: 120px;
        bottom: 30px;
      }
    }

    &:before,
    &:after {
      @screen lg {
        @apply absolute border-t;
        content: '';
        width: 85px;
        height: 0;
        transform-origin: center center;
        transform: rotate(-45deg);
      }
    }
  }

  .info {
    max-width: 480px;
  }

  .icon-home,
  .icon-print {
    top: 18px;
    right: 16px;
  }
</style>
