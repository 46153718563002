<template>
  <img
    class="image max-h-thumb-s"
    v-lazy="thumbnail"
    :alt="product.name"
  >
</template>

<script>
  import { MicrocartProduct } from '@vue-storefront/core/modules/cart/components/Product.ts'

  export default {
    name: 'ProductImage',
    mixins: [MicrocartProduct]
  }
</script>
