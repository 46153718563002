<template>
  <span class="text-sm text-grey-2 font-semibold uppercase">
    {{ `${getTotalPointsPrice} ${$t('Pts')}`}}
  </span>
</template>

<script>
  import PriceRules from 'theme/mixins/blocks/PriceRules'
  import { getProductQtyIncrement } from 'theme/helpers/getProductQtyIncrement'

  export default {
    name: 'TotalPointsPrice',
    mixins: [
      PriceRules
    ],
    props: {
      product: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        incrementStep: 1,
        rulePrice: null
      }
    },
    beforeMount () {
      this.incrementStep = getProductQtyIncrement(this.product)

      this.rulePrice = this.getRulePrice(this.product)
    },
    computed: {
      getTotalPointsPrice () {
        let price = (this.product.point_redemption_price * (this.product.qty ? this.product.qty : this.incrementStep)).toFixed(2)
        if (this.rulePrice) price = (this.rulePrice * (this.product.qty ? this.product.qty : this.incrementStep)).toFixed(2)
        return price
      },
    }
  }
</script>
