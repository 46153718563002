<template>
  <tr v-if="isLoyaltyItem">
    <td class="text-left text-base leading-h4 tracking-xs">
      {{ product.name | htmlDecode }}
      <span class="inline-block text-average text-grey-23 pl-xs" v-if="product.weight">
        {{ `${product.weight} Oz` }}
      </span>
    </td>
    <td class="points text-right text-md leading-h4 tracking-xs">
      <span>&diams; </span>
      <span class="" v-if="!rulePrice">
            {{(product.point_redemption_price || 0).toFixed(2) }}
          </span>
      <span class="" v-else>
            {{ rulePrice }}
          </span>
      <span>{{ $t('Pts') }}</span>
    </td>
    <td class="text-right text-base leading-h4 tracking-xs">
      <span class="block">{{ getLoyaltyQuantity }}</span>
    </td>
    <td class="text-right text-base leading-h4 tracking-xs pl-sm">
      <span>&diams; </span>
      <span class="" v-if="!rulePrice">
            {{(product.point_redemption_price || 0).toFixed(2) * (getLoyaltyQuantity || 1) }}
          </span>
      <span class="" v-else>
            {{ rulePrice * (getLoyaltyQuantity || 1) }}
          </span>
      <span>{{ $t('Pts') }}</span>
    </td>
  </tr>
</template>

<script>
import Product from 'theme/mixins/blocks/Product'

export default {
  name: 'OrderReviewProductLoyalty',
  components: {
  },
  mixins: [Product],
  props: {
    product: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
