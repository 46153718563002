<template>
  <div :class="{'active': isActive, 'processed': isProcessed && !isActive}">
    <div
      class="step-title mb-0 cursor-default text-white block font-black text-base leading-none uppercase tracking-xl"
      @click="goToStep('shippingMethod')"
    >
      {{ $t('Payment Information') }}
    </div>
    <div id="checkout-step-payment"
         class="step a-item border-solid border-grey-1 relative"
         v-if="isActive">
<!--      <base-radiobutton-->
<!--        :id="'radio-payment-method1'"-->
<!--        :value="creditCard === true"-->
<!--        @click.native="creditCard = true"-->
<!--      >-->
<!--        <span class="text-base leading-none tracking-xs text-dark pl-md">-->
<!--          {{ $t('Credit Card') }}-->
<!--        </span>-->
<!--      </base-radiobutton>-->
<!--      <div v-if="creditCard" class="mb-sm">-->
<!--        <div class="text-right text-average uppercase text-grey-23 hover:text-grey-15">-->
<!--          <span-->
<!--            class="payment-switcher cursor-pointer"-->
<!--            v-if="singlePaymentMethod"-->
<!--            @click="singlePaymentMethod = !singlePaymentMethod"-->
<!--          >-->
<!--            {{ $t('Use multiple payment methods') }}-->
<!--          </span>-->
<!--          <span-->
<!--            class="payment-switcher cursor-pointer"-->
<!--            v-if="!singlePaymentMethod"-->
<!--            @click="singlePaymentMethod = !singlePaymentMethod"-->
<!--          >-->
<!--            {{ $t('Use single payment method') }}-->
<!--          </span>-->
<!--        </div>-->
<!--        <div v-if="singlePaymentMethod">-->
<!--          <div class="-ml-sm -mr-sm clearfix">-->
<!--            <div class="w-1/2 pl-sm pr-sm mb-md float-left">-->
<!--              <base-input-->
<!--                :autofocus="true"-->
<!--                name="credit-card-name"-->
<!--                :type="'text'"-->
<!--              >-->
<!--                <span>*</span> {{ $t('Name on card') }}-->
<!--              </base-input>-->
<!--            </div>-->
<!--            <div class="w-1/4 pl-sm pr-sm mb-md float-left">-->
<!--              <base-select-->
<!--                name="types"-->
<!--                :options="typesOptions"-->
<!--                :placeholder="$t('&#45;&#45;Please Select&#45;&#45;')"-->
<!--                :selected="cardData.type"-->
<!--                v-model="cardData.type"-->
<!--                autocomplete="card-type"-->
<!--              >-->
<!--                <span>*</span> {{ $t('Credit Card Type') }}-->
<!--              </base-select>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="-ml-sm -mr-sm clearfix">-->
<!--            <div class="w-1/2 pl-sm pr-sm mb-md float-left">-->
<!--              <base-input-->
<!--                :autofocus="true"-->
<!--                name="credit-card-number"-->
<!--                :type="'number'"-->
<!--              >-->
<!--                <span>*</span> {{ $t('Credit card number') }}-->
<!--              </base-input>-->
<!--            </div>-->
<!--            <div class="w-1/4 pl-sm pr-sm mb-md float-left">-->
<!--              <base-select-->
<!--                name="months"-->
<!--                :options="monthsOptions"-->
<!--                :placeholder="$t('Month')"-->
<!--                :selected="cardData.month"-->
<!--                v-model="cardData.month"-->
<!--                autocomplete="card-month"-->
<!--              >-->
<!--                <span>*</span> {{ $t('Expiration Date') }}-->
<!--              </base-select>-->
<!--            </div>-->
<!--            <div class="w-1/4 pl-sm pr-sm mb-md float-left">-->
<!--              <base-select-->
<!--                name="years"-->
<!--                :options="yearsOptions"-->
<!--                :placeholder="$t('Year')"-->
<!--                :selected="cardData.year"-->
<!--                v-model="cardData.year"-->
<!--                autocomplete="card-year"-->
<!--              >-->
<!--                <span class="invisible">-->
<!--                   <span>*</span> {{ $t('Year') }}-->
<!--                </span>-->
<!--              </base-select>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="-ml-sm -mr-sm clearfix">-->
<!--            <div class="w-1/2 pl-sm pr-sm mb-md float-left">-->
<!--              <base-input-->
<!--                :autofocus="true"-->
<!--                name="credit-card-order"-->
<!--                :type="'text'"-->
<!--              >-->
<!--                <span>*</span> {{ $t('Purchase Order Number') }}-->
<!--              </base-input>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <base-radiobutton
        id="radio-payment-stored-cards"
        v-if="getCardsLength"
        :value="storedCardsPayment === true"
        @click.native="storedCardsPayment = true"
      >
        <span class="text-base leading-none tracking-xs text-dark pl-md">
          {{ $t('Store Credit Cards') }}
        </span>
      </base-radiobutton>
      <div v-if="storedCardsPayment && getCardsLength" class="mb-sm">
        <div v-for="(item, key) in storedCardsByBrand"
             :key="key">
          <div v-if="item.cards.length && brandsInCart.indexOf(item.brandId) !== -1">
            <div class="mb-md mt-lg">
              <i :class="`icon-${item.icon}`"></i>
            </div>
            <div class="-ml-sm -mr-sm clearfix">
              <div class="w-full lg:w-1/2 pl-sm pr-sm mb-md lg:float-left">
                <base-select
                  name="stored-card"
                  :options="item.cards"
                  ref="storedCard"
                  v-on:input="onStoredCardChange($event, key)"
                  v-on:change="onStoredCardChange($event, key)"
                  v-model="cards[key].id"
                >
                  <span class="text-red">*</span>
                  {{ $t('Stored credit card') }}
                </base-select>
              </div>
              <div class="w-full lg:w-1/3 pl-sm pr-sm mb-md lg:float-left">
                <base-input
                  :autofocus="true"
                  name="credit-card-order"
                  type="text"
                  :max-length="45"
                  v-model="cards[key]['po_number']"
                >
                  {{ $t('Purchase Order Number') }}
                </base-input>
              </div>
            </div>
          </div>
          <div v-else-if="setDisableContinue(item.cards.length === 0 && brandsInCart.indexOf(item.brandId) !== -1)">
            <div class="mb-md mt-lg">
              <i :class="`icon-${item.icon}`"></i>
            </div>
            <div class="p-3 mb-3">
              {{ $t('You have no stored cards in this location!') }}
              <br>
              {{ $t('Please contact your account consultant to add a credit card.') }}
            </div>
          </div>
        </div>
      </div>
      <base-radiobutton
        id="radio-payment-terms"
        v-if="getTerms"
        :value="storedCardsPayment === false"
        @click.native="storedCardsPayment = false"
      >
        <span class="text-base leading-none tracking-xs text-dark pl-md">
          {{ $t('Terms') }}
        </span>
      </base-radiobutton>
      <div v-if="!storedCardsPayment && getTerms" class="mb-sm">
        <div class="w-full md:w-1/2">
          <base-input
            :autofocus="true"
            name="terms-order"
            type="text"
            :max-length="45"
            v-model="termsPONumber"
          >
            {{ $t('Purchase Order Number') }}
          </base-input>
        </div>
      </div>
      <div class="p-3 mb-3 text-error shake" v-if="!hasValidPaymentMethods">
        Please <a class="underline font-bold" href="/contact" target="_blank">contact your account consultant</a> to add a credit card or terms to your account.
      </div>
      <div class="flex mt-lg mb-25px">
        <button-full
          class="back-button relative px-5 w-1/12 mr-sm"
          @click.native="toShippingMethod"
        >
          <i class="ss-gizmo back-icon ss-left absolute text-h5"></i>
          <span>{{ $t('Back') }}</span>
        </button-full>
        <button-full
          class="checkout-button relative w-2/3 px-5 lg:w-1/4"
          @click.native="toOrderReviewStep"
          :disabled="disableContinue || !hasValidPaymentMethods"
        >
          <span>{{ $t('Continue') }}</span>
          <i class="ss-gizmo checkout-icon ss-right absolute text-h5"></i>
        </button-full>
      </div>
      <div class="uppercase tracking-md text-h6 leading-none mt-sm">* {{ $t('Required fields') }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { Payment } from '@vue-storefront/core/modules/checkout/components/Payment'
import StoredCards from 'theme/mixins/blocks/StoredCards'
import BaseCheckbox from 'theme/components/core/blocks/Form/BaseCheckbox'
import BaseRadiobutton from 'theme/components/core/blocks/Form/BaseRadiobutton'
import GoToCheckoutStep from 'theme/mixins/blocks/GoToCheckoutStep'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput'
import BaseSelect from 'theme/components/core/blocks/Form/BaseSelect'
import ButtonFull from 'theme/components/theme/ButtonFull'
import Tooltip from 'theme/components/core/Tooltip'
import ButtonOutline from 'theme/components/theme/ButtonOutline'

export default {
  name: 'Payment',
  components: {
    ButtonOutline,
    BaseCheckbox,
    BaseInput,
    BaseSelect,
    ButtonFull,
    Tooltip,
    BaseRadiobutton
  },
  mixins: [
    Payment,
    StoredCards,
    GoToCheckoutStep
  ],
  data () {
    return {
      creditCard: false,
      isProcessed: false,
      disableContinue: false,
      storedCardsPayment: true,
      paymentByBrand: {
        rco: {
          name: 'R and Co',
          icon: 'rco text-caption',
          brandId: 1,
          locationId: null,
          cards: [],
        },
        v76: {
          name: 'V76',
          icon: 'v76 text-h1',
          brandId: 2,
          locationId: null,
          cards: []
        },
        sc: {
          name: 'Smith and Cult',
          icon: 'smith-cult text-caption',
          brandId: 3,
          locationId: null,
          cards: []
        },
        rco_bleu: {
          name: 'R+Co Bleu',
          icon: 'bleu',
          brandId: 5,
          locationId: null,
          cards: []
        },
        rco_color: {
          name: 'R+Color',
          icon: 'color',
          brandId: 6,
          locationId: null,
          cards: []
        }
      },
      storedCardsCode: 'stored_card',
      storedCardsByBrand: {},
      termsCode: 'terms',
      termsPONumber: '',
      cards: {
        rco: {
          id: '',
          po_number: ''
        },
        v76: {
          id: '',
          po_number: ''
        },
        sc: {
          id: '',
          po_number: ''
        },
        rco_bleu: {
          id: '',
          po_number: ''
        }
      },
      brandsInCart: []
    }
  },
  async beforeMount () {
    await this.$store.dispatch('storedCards/getStoredCards')
    await this.$store.dispatch('storedCards/syncCards')
    await this.$store.dispatch('storedCards/syncLocation')
  },
  async mounted () {
    await this.$nextTick()
    this.storedCardsByBrand = this.getStoredCards
    this.cartItems.forEach(item => {
      let childBrand = this.getChildBrands.find(brand => brand.id === item.brand_id)

      if (childBrand) {
        this.brandsInCart.push(childBrand.parent_id)
      } else {
        this.brandsInCart.push(item.brand_id)
      }
    })

    for (let key in this.cards) {
      let brandCards = this.storedCardsByBrand[key]['cards']

      if (this.brandsInCart.indexOf(this.storedCardsByBrand[key]['brandId']) < 0) continue

      let defaultSet = false
      brandCards.forEach((cc) => {
        if(cc.isDefault) {
          this.$set(this.cards, key, Object.assign({}, this.cards[key], {id: cc.value.toString()}))
          defaultSet = true
          return true
        }
      })

      // just set the first item
      if (!defaultSet) {
        brandCards.forEach((cc) => {
          this.$set(this.cards, key, Object.assign({}, this.cards[key], {id: cc.value.toString()}))
          return true
        })
      }
    }
    this.$nextTick(() => {
      this.storedCardsPayment = !!this.getCardsLength
    })
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.user.current,
      hasPaymentMethodSelected: (state) => state.shipping.payment_method
    }),
    ...mapGetters({
      cartItems: 'cart/getCartItems',
      getBrands: 'brands/getBrands'
    }),
    hasValidPaymentMethods () {
      return [
        this.getCardsLength,
        this.getTerms
      ].some(e => e)
    },
    getTerms () {
      return this.currentUser.extension_attributes.terms_id
    },
    getStoredCards () {
      let cardsByBrand = this.paymentByBrand

      let addressId = (this.getLocation || {}).addressId
      let locations = this.currentUser.extension_attributes.locations_data || []

      locations = locations.filter((loc) => loc.address_id == addressId)

      let storedCards = this.getStoredCardsList

      for (let key in storedCards) {
        if (!storedCards.hasOwnProperty(key) || !cardsByBrand.hasOwnProperty(key)) continue

        cardsByBrand[key]['locationId'] = storedCards[key]['locationId']

        // filter only cards that exist for location
        let cardOptions = storedCards[key]['storedCards'].filter((card) => {
          return locations.filter(lc => lc.location_id == card.locationId).length > 0
        })

        cardsByBrand[key]['cards'] = this.getStoredCardsOptions(cardOptions)
      }

      if (!this.getChildBrands.length) return cardsByBrand

      this.getChildBrands.forEach(brand => {
        if (this.brandsInCart.indexOf(brand['brandId']) < 0) return

        let parentBrand = Object.keys(cardsByBrand)
          .find(key => cardsByBrand[key]['brandId'] === brand['parent_id'])

        if (cardsByBrand[parentBrand] && cardsByBrand[brand.code] && cardsByBrand[brand.code].cards.length) {
          cardsByBrand[parentBrand].cards.push(...cardsByBrand[brand.code].cards)
        }
      })

      return cardsByBrand
    },
    getCardsLength () {
      let num = 0

      for (let key in this.storedCardsByBrand) {
        num += this.storedCardsByBrand[key]['cards'].length
      }

      return num
    },
    locationId () {
      return this.getLocation ? this.getLocation.locationId : ''
    },
    brandId () {
      return this.getLocation ? this.getLocation.brandId : null
    },
    getChildBrands () {
      return this.getBrands.filter(brand => brand['parent_id'])
    },
    termsPaymentInformation () {
      let addressId = (this.getLocation || {}).addressId
      let address = this.currentUser.addresses.find(address => address.id == addressId)

      let locations = {}

      let brandIds = []
      for (let key in this.cartItems) {
        brandIds.push(this.cartItems[key].brand_id)
      }
      brandIds = [...new Set(brandIds)]

      let availableLocations = this.currentUser.extension_attributes.locations_data || []

      for (let key in availableLocations) {
        let obj = availableLocations[key]
        if(obj.address_id == address.id && brandIds.includes(obj.brand_id)) {
          locations[obj.brand_id] = obj.location_id
        }
      }

      return {
        method: this.termsCode,
        paymentMethodAdditional: {
          locations: locations,
          po_number: this.termsPONumber,
        }
      }
    },
    storedCardsPaymentInformation () {
      let cards = []

      for (let key in this.cards) {
        if (!this.cards[key]['id']) continue

        cards.push(Object.assign({}, this.cards[key]))
      }

      return {
        method: this.storedCardsCode,
        paymentMethodAdditional: {
          cards: cards
        }
      }
    }
  },
  methods: {
    onStoredCardChange (event, key) {
      this.cards[key].id = event
      this.$forceUpdate()
    },
    setDisableContinue (state) {
      this.disableContinue = state
      return state
    },
    toShippingMethod () {
      this.$bus.$emit('checkout-set-active-section', 'shippingMethod')
    },
    toOrderReviewStep () {
      if (this.storedCardsPayment) {
        this.$store.state.shipping.payment_method = this.storedCardsCode
        this.$bus.$emit('checkout-after-paymentDetails', Object.assign({}, this.payment, this.$store.state.checkout.paymentDetails, { paymentMethod: this.storedCardsCode, paymentMethodAdditional: this.storedCardsPaymentInformation.paymentMethodAdditional }), this.storedCardsPaymentInformation)
      } else {
        this.$store.state.shipping.payment_method = this.termsCode
        this.$bus.$emit('checkout-after-paymentDetails', Object.assign({}, this.payment, this.$store.state.checkout.paymentDetails, { paymentMethod: this.termsCode, paymentMethodAdditional: this.termsPaymentInformation.paymentMethodAdditional }), this.termsPaymentInformation)
      }
      this.$bus.$emit('checkout-payment-method-changed', this.payment.paymentMethod)
      this.isFilled = true
      this.isProcessed = true
      this.$bus.$emit('checkout-set-active-section', 'orderReview')
    },
    getStoredCardsOptions (cards) {
      let options = []

      cards.forEach((card, index) => {
        let isDefaultText = card.isDefault ? '(Default Credit Card)' : ''
        let label = `${card.number} ${card.type} ${card.expDate} ${isDefaultText}` + (card.expired ? ' [Expired]' : '')

        options.push({
          value: card.id,
          label: label,
          isDefault: card.isDefault
        })
      })

      return options
    }
  },
  watch: {
    isActive: {
      handler: async function (newVal, oldVal) { // watch it
        if (newVal) {
          await this.$nextTick()
          this.changePaymentMethod()
          this.storedCardsPayment = !!this.getCardsLength

          if (this.$refs.storedCard && this.$refs.storedCard[0] && this.$refs.storedCard[0].$refs.select) {
            this.$refs.storedCard[0].$refs.select.dispatchEvent(new Event('change'))
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
  .verify-input {
    input {
      max-width: 100px;
    }
  }

  .icon-q {
    position: absolute;
    right: 5px;
    top: 35px;
  }

  .shake:hover {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }

  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
</style>
