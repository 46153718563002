import { mapState } from 'vuex'

export const BillingInformation = {
  name: 'BillingInformation',
  props: {
    isActive: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      billingInformation: this.$store.state.checkout.paymentDetails
    }
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.user.current
    })
  },
  methods: {
    onLoggedIn (receivedData) {
      this.personalDetails = {
        firstName: receivedData.firstname,
        lastName: receivedData.lastname,
        emailAddress: receivedData.email
      }
    },
    sendDataToCheckout () {
      this.$bus.$emit('checkout-after-billingInformation', this.billingInformation, this.shipThisAddress)
    }
  },
  beforeMount () {
    this.$bus.$on('user-after-loggedin', this.onLoggedIn)
  },
  destroyed () {
    this.$bus.$off('user-after-loggedin', this.onLoggedIn)
  }
}
