import i18n from '@vue-storefront/i18n'
import store from '@vue-storefront/core/store'

import { currentStoreView } from '@vue-storefront/core/lib/multistore'
// import { initializeSyncTaskStorage } from '@vue-storefront/core/lib/sync/task'
import { isServer } from '@vue-storefront/core/helpers'
import { Logger } from '@vue-storefront/core/lib/logger'
import Checkout from '@vue-storefront/core/pages/Checkout'
import UniversalStorage from '@vue-storefront/core/store/lib/storage'
import * as localForage from 'localforage'
import { mapGetters, mapState } from 'vuex'
import pick from 'lodash-es/pick'

/*
    Function from core lib: core/lib/sync/task.ts
 */
function syncTaskCollection () {
  const storeView = currentStoreView()
  const dbNamePrefix = storeView.storeCode ? storeView.storeCode + '-' : ''

  return new UniversalStorage(localForage.createInstance({
    name: dbNamePrefix + 'shop',
    storeName: 'syncTasks',
    driver: localForage[store.state.config.localForage.defaultDrivers['syncTasks']]
  }))
}

export default {
  name: 'Checkout',
  extends: Checkout,
  data () {
    return {
      userId: null,
      stockCheckCompleted: false,
      stockCheckOK: false,
      orderPlaced: false,
      confirmation: null, // order confirmation from server
      activeSection: {
        checkoutMethod: false,
        billingInformation: false,
        personalDetails: false, // TODO: Remove
        shipping: false,
        shippingMethod: false,
        payment: false,
        orderReview: false
      },
      order: {},
      billingInformation: {},
      personalDetails: {},
      shipping: {},
      shippingMethod: {
        method_code: '', // DEMO
        carrier_code: '' // DEMO
      },
      payment: {},
      orderReview: {},
      cartSummary: {},
      validationResults: {
        personalDetails: { $invalid: true },
        shipping: { $invalid: true },
        payment: { $invalid: true }
      },
      focusedField: null,
      createAccount: false
    }
  },
  computed: {
    shippingMethods () {
      return this.$store.getters['shipping/shippingMethods']
    },
    availableShippingMethods () {
      return this.shippingMethods ? this.shippingMethods.filter(method => method.available) : []
    },
    ...mapGetters({
      location: 'storedCards/getLocation',
      isThankYouPage: 'checkout/isThankYouPage'
    }),
    ...mapState({
      customerAddresses: (state) => (state.user.current || []).addresses
    }),
    shippingAddressByLocationId () {
      return this.customerAddresses.find(item => item.id === (this.location || {}).addressId)
    }
  },
  beforeMount () {
    if (this.userId === null) {
      this.activeSection.checkoutMethod = true
      this.activeSection.personalDetails = false
    } else {
      this.activeSection.personalDetails = true
    }
    // Custom theme events
    this.$bus.$on('checkout-after-checkout-method', this.onAfterCheckoutMethod)
    this.$bus.$on('checkout-set-active-section', this.setActiveSection)
    this.$bus.$on('checkout-set-create-account', this.setCreateAccount)
    // Core events
    // TO-DO: Use one event with name as apram
    this.$bus.$on('cart-after-update', this.onCartAfterUpdate)
    this.$bus.$on('cart-after-delete', this.onCartAfterUpdate)
    // this.$bus.$on('checkout-after-personalDetails', this.onAfterPersonalDetails)
    this.$bus.$on('checkout-after-billingInformation', this.onAfterBillingInformation)
    this.$bus.$on('checkout-after-shippingDetails', this.onAfterShippingDetails)
    this.$bus.$on('checkout-after-paymentDetails', this.onAfterPaymentDetails)
    this.$bus.$on('checkout-after-cartSummary', this.onAfterCartSummary)
    this.$bus.$on('checkout-before-placeOrder', this.onBeforePlaceOrder)
    this.$bus.$on('checkout-do-placeOrder', this.onDoPlaceOrder)
    this.$bus.$on('checkout-before-edit', this.onBeforeEdit)
    this.$bus.$on('order-after-placed', this.onAfterPlaceOrder)
    // this.$bus.$on('checkout-before-shippingMethods', this.onBeforeShippingMethods)
    this.$bus.$on('checkout-after-shippingMethodChanged', this.onAfterShippingMethodChanged)
    this.$bus.$on('checkout-after-validationError', this.focusField)
    this.$store.dispatch('cart/load').then(() => {
      if (this.$store.state.cart.cartItems.length === 0) {
        this.notifyEmptyCart()
        this.$router.push(this.localizedRoute('/'))
      } else {
        this.stockCheckCompleted = false
        const checkPromises = []

        for (let product of this.$store.state.cart.cartItems) { // check the results of online stock check
          if (product.onlineStockCheckid) {
            checkPromises.push(new Promise((resolve, reject) => {
              syncTaskCollection().getItem(product.onlineStockCheckid, (err, item) => {
                if (err || !item) {
                  if (err) Logger.error(err)()
                  resolve(null)
                } else {
                  product.stock = item.result
                  resolve(product)
                }
              })
            }))
          }
        }
        Promise.all(checkPromises).then((checkedProducts) => {
          this.stockCheckCompleted = true
          this.stockCheckOK = true
          for (let chp of checkedProducts) {
            if (chp && chp.stock) {
              if (!chp.stock.is_in_stock) {
                this.stockCheckOK = false
                chp.errors.stock = i18n.t('Out of stock!')
                this.notifyOutStock(chp)
              }
            }
          }
        })
      }
    })

    this.payment = this.$store.state.checkout.paymentDetails

    const storeView = currentStoreView()
    let country = this.$store.state.checkout.shippingDetails.country

    if (!country) country = storeView.i18n.defaultCountry
    this.$bus.$emit('checkout-before-shippingMethods', country)
  },
  beforeDestroy () {
    // Custom theme events
    this.$bus.$off('checkout-after-checkout-method', this.onAfterCheckoutMethod)
    this.$bus.$off('checkout-set-active-section', this.setActiveSection)
    this.$bus.$off('checkout-set-create-account', this.setCreateAccount)

    this.$bus.$off('cart-after-update', this.onCartAfterUpdate)
    this.$bus.$off('cart-after-delete', this.onCartAfterUpdate)
    this.$bus.$off('checkout-after-billingInformation', this.onAfterBillingInformation)
    // this.$bus.$off('checkout-after-personalDetails', this.onAfterPersonalDetails)
    this.$bus.$off('checkout-after-shippingDetails', this.onAfterShippingDetails)
    this.$bus.$off('checkout-after-paymentDetails', this.onAfterPaymentDetails)
    this.$bus.$off('checkout-after-cartSummary', this.onAfterCartSummary)
    this.$bus.$off('checkout-before-placeOrder', this.onBeforePlaceOrder)
    this.$bus.$off('checkout-do-placeOrder', this.onDoPlaceOrder)
    this.$bus.$off('checkout-before-edit', this.onBeforeEdit)
    this.$bus.$off('order-after-placed', this.onAfterPlaceOrder)
    // this.$bus.$off('checkout-before-shippingMethods', this.onBeforeShippingMethods)
    this.$bus.$off('checkout-after-shippingMethodChanged', this.onAfterShippingMethodChanged)
    this.$bus.$off('checkout-after-validationError', this.focusField)
  },
  methods: {
    setCreateAccount (isCreate) {
      this.createAccount = isCreate
    },
    setActiveSection (section) {
      this.activateSection(section)
    },
    onAfterCheckoutMethod () {
      this.activateSection('personalDetails')
    },
    onAfterBillingInformation (receivedData, shipToThisAddress) {
      this.billingInformation = receivedData
      this.$store.dispatch('checkout/savePaymentDetails', this.billingInformation)

      if (!shipToThisAddress) return

      if (!this.shippingMethod.method_code && this.availableShippingMethods && this.availableShippingMethods.length) {
        this.shippingMethod = this.availableShippingMethods[0]
      }
      this.shipping = {
        ...this.shippingAddressByLocationId,
        shippingMethod: this.shippingMethod.method_code
      }
      this.saveShippingDetails()
      this.$bus.$emit('checkout-after-shippingset', this.shipping)
      this.activateSection('shipping')
    },
    onCartAfterUpdate (payload) {
      if (this.$store.state.cart.cartItems.length === 0) {
        this.notifyEmptyCart()
        this.$router.push(this.localizedRoute('/'))
      }
    },
    onAfterShippingMethodChanged (payload) {
      this.$store.dispatch('cart/refreshTotals', payload)
      this.shippingMethod = payload
    },
    // onBeforeShippingMethods (country) {
    //   this.$store.dispatch('cart/getShippingMethods', {
    //     country_id: country
    //   }).then(() => {
    //     this.$store.dispatch('cart/refreshTotals')
    //     this.$forceUpdate()
    //   })
    // },
    onAfterPlaceOrder (payload) {
      this.confirmation = payload.confirmation
      this.orderPlaced = true
      this.$store.dispatch('checkout/setThankYouPage', true)
      Logger.debug(payload.order)()
    },
    onBeforeEdit (section) {
      this.activateSection(section)
    },
    onBeforePlaceOrder (payload) {
    },
    onAfterCartSummary (receivedData) {
      this.cartSummary = receivedData
    },
    onDoPlaceOrder (additionalPayload) {
      if (this.$store.state.cart.cartItems.length === 0) {
        this.notifyEmptyCart()
        this.$router.push(this.localizedRoute('/'))
      } else {
        this.$store.dispatch('checkout/placeOrder', { order: this.prepareOrder() })
      }
    },
    onAfterPaymentDetails (receivedData, validationResult) {
      this.payment = receivedData
      this.validationResults.payment = validationResult
      this.activateSection('orderReview')
      this.savePaymentDetails()
    },
    onAfterShippingDetails (receivedData, validationResult) {
      this.shipping = receivedData
      this.validationResults.shipping = validationResult
      this.activateSection('shippingMethod')
      this.saveShippingDetails()

      const storeView = currentStoreView()

      storeView.tax.defaultCountry = this.shipping.country
    },
    onAfterPersonalDetails (receivedData, validationResult) {
      this.personalDetails = receivedData
      this.validationResults.personalDetails = validationResult

      if (this.isVirtualCart === true) {
        this.activateSection('payment')
      } else {
        this.activateSection('shipping')
      }
      this.savePersonalDetails()
      this.focusedField = null
    },
    onNetworkStatusCheck (isOnline) {
      this.checkConnection(isOnline)
    },
    validateFields () {
      let isValid = true

      for (let child of this.$children) {
        if (child.hasOwnProperty('$v')) {
          if (child.$v.$invalid) {
            // Check if child component is Personal Details.
            // If so, then ignore validation of account creation fields.
            if (child.$v.hasOwnProperty('personalDetails')) {
              if (child.$v.personalDetails.$invalid) {
                isValid = false
                break
              }
            } else {
              child.$v.$touch()
              this.activateSection(child.$el.id)
              isValid = false
              break
            }
          }
        }
      }

      if (!isValid) this.notifyFieldsInvalid()
      return isValid
    },
    checkStocks () {
      let isValid = true

      if (typeof navigator !== 'undefined' && navigator.onLine) {
        if (this.stockCheckCompleted) {
          if (!this.stockCheckOK) {
            isValid = false
            this.notifyNotAvailable()
          }
        } else {
          this.notifyStockCheck()
          isValid = false
        }
      }
      return isValid
    },
    activateHashSection () {
      if (!isServer) {
        let urlStep = window.location.hash.replace('#', '')

        if (this.activeSection.hasOwnProperty(urlStep) && this.activeSection[urlStep] === false) {
          this.activateSection(urlStep)
        } else if (urlStep === '') {
          this.activateSection('personalDetails')
        }
      }
    },
    checkConnection (isOnline) {
      if (!isOnline) {
        this.notifyNoConnection()
      }
    },
    activateSection (sectionToActivate) {
      for (let section in this.activeSection) {
        this.activeSection[section] = false
      }
      this.activeSection[sectionToActivate] = true
      if (!isServer) {
        window.location.href = window.location.origin + window.location.pathname + '#' + sectionToActivate

        this.$nextTick(() => {
          window.scroll({
            behavior: 'smooth',
            left: 0,
            top: ((document.getElementById(sectionToActivate) || {}).offsetTop || 25) - 25
          })
        })
      }
    },
    // This method checks if there exists a mapping of chosen payment method to one of Magento's payment methods.
    getPaymentMethod () {
      let paymentMethod = this.payment.paymentMethod

      if (store.state.config.orders.payment_methods_mapping.hasOwnProperty(paymentMethod)) {
        paymentMethod = store.state.config.orders.payment_methods_mapping[paymentMethod]
      }
      return paymentMethod
    },
    prepareOrder () {
      this.order = {
        user_id: this.$store.state.user.current && this.$store.state.user.current.id ? this.$store.state.user.current.id.toString() : (this.userId ? this.userId : ''),
        cart_id: this.$store.state.cart.cartServerToken ? this.$store.state.cart.cartServerToken : '',
        products: this.$store.state.cart.cartItems.map(x => pick(x, [
          'id', 'sku', 'price', 'qty',
          'name,', 'product_type', 'parentSku',
          'server_item_id', 'server_cart_id', 'product_option',
          'extension_attributes'
        ])),
        addressInformation: {
          billingAddress: {
            region: this.billingInformation.state,
            region_id: this.billingInformation.region_id ? this.billingInformation.region_id : 0,
            country_id: this.billingInformation.country,
            street: [this.billingInformation.streetAddress, this.billingInformation.apartmentNumber],
            company: this.billingInformation.company,
            telephone: this.billingInformation.phoneNumber,
            fax: this.billingInformation.fax,
            postcode: this.billingInformation.zipCode,
            city: this.billingInformation.city,
            firstname: this.billingInformation.firstName,
            lastname: this.billingInformation.lastName,
            email: this.billingInformation.emailAddress,
            region_code: this.billingInformation.region_code ? this.billingInformation.region_code : '',
            vat_id: this.payment.taxId
          },
          shipping_method_code: this.shippingMethod.method_code ? this.shippingMethod.method_code : this.shipping.shippingMethod,
          shipping_carrier_code: this.shippingMethod.carrier_code ? this.shippingMethod.carrier_code : this.shipping.shippingCarrier,
          payment_method_code: this.getPaymentMethod(),
          payment_method_additional: this.payment.paymentMethodAdditional ? {
            cards: JSON.stringify(this.payment.paymentMethodAdditional.cards),
            locations: JSON.stringify(this.payment.paymentMethodAdditional.locations),
            po_number: this.payment.paymentMethodAdditional.po_number
          } : {},
          shippingExtraFields: this.shipping.extraFields
        }
      }
      if (!this.isVirtualCart) {
        this.order.addressInformation.shippingAddress = {
          region: this.shipping.state,
          region_id: this.shipping.region_id ? this.shipping.region_id : 0,
          country_id: this.shipping.country,
          street: [this.shipping.streetAddress, this.shipping.apartmentNumber],
          company: this.shipping.company,
          telephone: this.shipping.phoneNumber,
          fax: this.shipping.fax,
          postcode: this.shipping.zipCode,
          city: this.shipping.city,
          firstname: this.shipping.firstName,
          lastname: this.shipping.lastName,
          email: this.personalDetails.emailAddress,
          region_code: this.shipping.region_code ? this.shipping.region_code : ''
        }
      }
      return this.order
    },
    placeOrder () {
      this.checkConnection({ online: typeof navigator !== 'undefined' ? navigator.onLine : true })
      if (this.validateFields() && this.checkStocks()) {
        this.$store.dispatch('checkout/placeOrder', { order: this.prepareOrder() })
      }
    },
    savePersonalDetails () {
      this.$store.dispatch('checkout/savePersonalDetails', this.personalDetails)
    },
    saveShippingDetails () {
      this.$store.dispatch('checkout/saveShippingDetails', this.shipping)
    },
    savePaymentDetails () {
      this.$store.dispatch('checkout/savePaymentDetails', this.payment)
    },
    focusField (fieldName) {
      if (fieldName === 'password') {
        window.scrollTo(0, 0)
        this.activateSection('personalDetails')
        this.focusedField = fieldName
      }
      if (fieldName === 'email-address') {
        window.scrollTo(0, 0)
        this.activateSection('personalDetails')
        this.focusedField = fieldName
      }
    },
    notifyEmptyCart () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'warning',
        message: this.$t('Shopping cart is empty. Please add some products before entering Checkout'),
        action1: { label: this.$t('OK') }
      })
    }
  },
  watch: {
    payment (newVal, oldVal) {
      this.billingInformation = Object.assign({}, this.billingInformation, newVal)
    }
  }
}
