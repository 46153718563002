<template>
  <div :class="{'active': isActive, 'processed': isProcessed && !isActive}">
    <div
      class="step-title mb-0 cursor-default text-white block font-black text-base leading-none uppercase tracking-xl"
      @click="goToStep('billingInformation')"
    >
      {{ $t('Billing Information') }}
    </div>
    <div id="checkout-step-billing" class="step a-item border-solid border-grey-1 relative" v-if="isActive">
      <form id="co-billing-form">
        <div class="-ml-sm -mr-sm clearfix">
          <div class="md:w-1/2 pl-sm pr-sm mb-md">
            <base-select :options="billingOptions" v-model="isNewBillingAddress" :selected="isNewBillingAddress">
              {{ $t('Select a billing address from your address book or enter a new address.') }}
<!--              <template #additionalOption>-->
<!--                <option :value="newAddress.value">-->
<!--                  {{ $t(`${newAddress.title}`) }}-->
<!--                </option>-->
<!--              </template>-->
            </base-select>
          </div>
        </div>
<!--        <div v-if="!isNewBillingAddress || !customerAddresses.length">-->
<!--          <div class="-ml-sm -mr-sm clearfix">-->
<!--            <div class="w-1/2 pl-sm pr-sm mb-md float-left">-->
<!--              <base-input id="billing-first-name" :autofocus="true" :validations="[-->
<!--                  {-->
<!--                    condition: $v.billingInformation.firstName.$error && !$v.billingInformation.firstName.required,-->
<!--                    text: $t('Field is required')-->
<!--                  },-->
<!--                  {-->
<!--                    condition: !$v.billingInformation.firstName.minLength,-->
<!--                    text: $t('Name must have at least 2 letters.')-->
<!--                  }-->
<!--                ]"-->
<!--                @blur="$v.billingInformation.firstName.$touch()" @keyup.enter="next" autocomplete="given-name" name="first-name"-->
<!--                type="text" v-model.trim="billingInformation.firstName" ref="first-name">-->
<!--                <span>*</span> {{ $t('First name') }}-->
<!--              </base-input>-->
<!--            </div>-->
<!--            <div class="w-1/2 pl-sm pr-sm mb-md float-left">-->
<!--              <base-input id="billing-last-name" :validations="[{-->
<!--                  condition: $v.billingInformation.lastName.$error && !$v.billingInformation.lastName.required,-->
<!--                  text: $t('Field is required')-->
<!--                }]"-->
<!--                @keyup.enter="next" @blur="$v.billingInformation.lastName.$touch()" autocomplete="family-name" name="last-name"-->
<!--                type="text" ref="last-name" v-model.trim="billingInformation.lastName">-->
<!--                <span>*</span> {{ $t('Last name') }}-->
<!--              </base-input>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="-ml-sm -mr-sm clearfix mb-10">-->
<!--            <div class="w-1/2 pl-sm pr-sm mb-md float-left">-->
<!--              <base-input id="billing-company" @keyup.enter="next" autocomplete="company" name="company" type="text"-->
<!--                v-model="billingInformation.company" ref="company">-->
<!--                {{ $t('Company') }}-->
<!--              </base-input>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="-ml-sm -mr-sm clearfix">-->
<!--            <div class="w-1/2 pl-sm pr-sm mb-md float-left">-->
<!--              <base-input id="billing-street-address" @keyup.enter="next" autocomplete="streetAddress" @blur="$v.billingInformation.streetAddress.$touch()"-->
<!--                name="street-address" type="text" v-model="billingInformation.streetAddress" ref="street-address"-->
<!--                :validations="[-->
<!--                  {-->
<!--                    condition: $v.billingInformation.streetAddress.$error && !$v.billingInformation.streetAddress.required,-->
<!--                    text: $t('Field is required')-->
<!--                  }-->
<!--                ]">-->
<!--                <span>*</span> {{ $t('Address Line 1') }}-->
<!--              </base-input>-->
<!--            </div>-->
<!--            <div class="w-1/2 pl-sm pr-sm mb-md float-left">-->
<!--              <base-input id="billing-apartment-number" :validations="[]" @keyup.enter="next" autocomplete="apartmentNumber"-->
<!--                name="apartment-number" type="text" v-model="billingInformation.apartmentNumber" ref="apartment-number">-->
<!--                {{ $t('Address Line 2') }}-->
<!--              </base-input>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="-ml-sm -mr-sm clearfix">-->
<!--            <div class="w-1/2 pl-sm pr-sm mb-md float-left">-->
<!--              <base-input id="billing-city" @keyup.enter="next" autocomplete="city" @blur="$v.billingInformation.city.$touch()"-->
<!--                name="city" type="text" v-model="billingInformation.city" :validations="[-->
<!--                  {-->
<!--                    condition: $v.billingInformation.city.$error && !$v.billingInformation.city.required,-->
<!--                    text: $t('Field is required')-->
<!--                  }-->
<!--                ]"-->
<!--                ref="city">-->
<!--                <span>*</span> {{ $t('City') }}-->
<!--              </base-input>-->
<!--            </div>-->
<!--            <div class="w-1/2 pl-sm pr-sm mb-md float-left">-->
<!--              <base-select name="state" id="billing-state" :options="statesOptions" :selected="billingInformation.state"-->
<!--                :validations="[-->
<!--                  {-->
<!--                    condition: $v.billingInformation.state.$error && !$v.billingInformation.state.required,-->
<!--                    text: $t('Field is required')-->
<!--                  }-->
<!--                ]"-->
<!--                v-model="billingInformation.state" autocomplete="state-name" @blur="$v.billingInformation.state.$touch()"-->
<!--                @change="$v.billingInformation.state.$touch()" ref="state">-->
<!--                <span>*</span> {{ $t('State/Province') }}-->
<!--              </base-select>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="-ml-sm -mr-sm clearfix mb-10">-->
<!--            <div class="w-1/4 pl-sm pr-sm mb-md float-left">-->
<!--              <base-input id="billing-zip-code" @keyup.enter="next" autocomplete="zip" @blur="$v.billingInformation.zipCode.$touch()"-->
<!--                name="zipCode" type="text" v-model="billingInformation.zipCode" :validations="[-->
<!--                  {-->
<!--                    condition: $v.billingInformation.zipCode.$error && !$v.billingInformation.zipCode.required,-->
<!--                    text: $t('Field is required')-->
<!--                  }-->
<!--                ]"-->
<!--                ref="zipCode">-->
<!--                <span>*</span> {{ $t('Zip') }}-->
<!--              </base-input>-->
<!--            </div>-->
<!--            <div class="w-1/2 pl-sm pr-sm mb-md float-left">-->
<!--              <base-select id="billing-country" name="country" :options="countryOptions" :selected="billingInformation.country"-->
<!--                :validations="[-->
<!--                {-->
<!--                    condition: $v.billingInformation.country.$error && !$v.billingInformation.country.required,-->
<!--                    text: $t('Field is required')-->
<!--                  }-->
<!--                ]"-->
<!--                v-model="billingInformation.country" autocomplete="country-name" @blur="$v.billingInformation.country.$touch()"-->
<!--                @change="$v.billingInformation.country.$touch()" ref="country">-->
<!--                <span>*</span> {{ $t('Country') }}-->
<!--              </base-select>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="-ml-sm -mr-sm clearfix mb-md">-->
<!--            <div class="w-1/2 pl-sm pr-sm mb-md float-left">-->
<!--              <base-input id="billing-phone-number" @keyup.enter="next" autocomplete="phoneNumber" name="phone-number"-->
<!--                @blur="$v.billingInformation.phoneNumber.$touch()" type="text" ref="phone-number" v-model="billingInformation.phoneNumber"-->
<!--                :validations="[-->
<!--                  {-->
<!--                    condition: $v.billingInformation.phoneNumber.$error && !$v.billingInformation.phoneNumber.required,-->
<!--                    text: $t('Field is required')-->
<!--                  }-->
<!--                ]">-->
<!--                <span>*</span> {{ $t('Telephone') }}-->
<!--              </base-input>-->
<!--            </div>-->
<!--            <div class="w-1/2 pl-sm pr-sm mb-md float-left">-->
<!--              <base-input id="billing-fax" :validations="[]" @keyup.enter="next" autocomplete="fax" name="fax" type="text"-->
<!--                v-model="billingInformation.fax" ref="fax">-->
<!--                {{ $t('Fax') }}-->
<!--              </base-input>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="text-h6 uppercase leading-none tracking-mid">-->
<!--            {{ $t('To edit, add or remove billing addresses from your saved address book, please contact your account consultant:') }}-->
<!--            <span class="text-brown-2">305-600-1305</span>.-->
<!--          </div>-->
<!--        </div>-->
        <button-full class="checkout-button flex-grow relative px-5 mt-8 md:w-1/4 w-full"
          @click.native="next">
          <span>{{ $t('Continue') }}</span>
          <i class="ss-gizmo checkout-icon ss-right absolute text-h5"></i>
        </button-full>
<!--        <p class="uppercase tracking-mid text-h6 leading-none mt-md" v-if="!isNewBillingAddress || !customerAddresses.length">*-->
<!--          {{ $t('Required fields') }}-->
<!--        </p>-->
      </form>
    </div>
  </div>
</template>
<script>
import {
  required,
  minLength,
  email,
  sameAs
} from 'vuelidate/lib/validators'
// import AmazonPayAddressBook from 'src/modules/vsf-payment-amazon/components/AddressBook'
import {
  BillingInformation
} from 'theme/modules/checkout/components/BillingInformation'
import BaseCheckbox from 'theme/components/core/blocks/Form/BaseCheckbox'
import GoToCheckoutStep from 'theme/mixins/blocks/GoToCheckoutStep'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput'
import BaseRadiobutton from 'theme/components/core/blocks/Form/BaseRadiobutton'
import BaseSelect from 'theme/components/core/blocks/Form/BaseSelect'
import ButtonFull from 'theme/components/theme/ButtonFull'
import ButtonOutline from '../../../theme/ButtonOutline'
import {
  mapState, mapGetters
} from 'vuex'
import config from 'config'
import {
  httpClient
} from 'theme/helpers/httpClient'
import i18n from '@vue-storefront/i18n'
import isArray from 'lodash-es/isArray'
const Countries = require('theme/resource/geo/countries.json')
const USAStates = require('theme/resource/geo/usa-states.json')
export default {
  name: 'Billing',
  components: {
    ButtonOutline,
    ButtonFull,
    BaseCheckbox,
    BaseInput,
    BaseSelect,
    BaseRadiobutton,
    // AmazonPayAddressBook
  },
  mixins: [
    BillingInformation,
    GoToCheckoutStep
  ],
  props: {
    isActive: {
      type: null,
      required: true
    }
  },
  data() {
    return {
      newAddress: {
        title: 'New Address',
        value: null
      },
      isProcessed: false,
      shipThisAddress: true,
      countries: Countries,
      // allStates: null,
      isNewBillingAddress: '',
      states: USAStates
    }
  },
  mounted() {
    if (this.currentUser) {
      this.fillFromUserAccount()
    }
    this.isNewBillingAddress = this.location.addressId
  },
  created() {
    if (typeof this.countries[0] !== 'undefined') {
      this.billingInformation.country = this.countries[0].code
    }
    if (typeof this.states[0] !== 'undefined') {
      this.billingInformation.state = this.states[0].code
    }
  },
  computed: {
    billingOptions() {
      return this.getBillingOptions(this.customerAddresses)
    },
    countryOptions() {
      return (this.countries || []).map((item) => {
        return {
          value: item.code,
          label: item.name
        }
      })
    },
    statesOptions() {
      return (this.states || []).map((item) => {
        return {
          value: item.code,
          label: item.name
        }
      })
    },
    currentState() {
      return this.billingInformation.state
    },
    payment() {
      return this.$store.state.checkout.paymentDetails
    },
    ...mapState({
      customerAddresses: (state) => state.user.current.addresses
    }),
    ...mapGetters({
      location: 'storedCards/getLocation'
    }),
    addressFromCustomerAddresses() {
      return this.customerAddresses.find(item => item.id == this.isNewBillingAddress) || {}
    }
  },
  methods: {
    fillFromUserAccount() {
      this.billingInformation.firstName = this.currentUser.firstname
      this.billingInformation.lastName = this.currentUser.lastname
      this.billingInformation.emailAddress = this.currentUser.email
    },
    getBillingOptions(address) {
      return (address || []).map((item) => {
        let apartmentNumber = item.street[1] ? item.street[1] : ''
        return {
          value: item.id,
          label: `${item.firstname} ${item.lastname}, ${item.street[0]} ${apartmentNumber}, ${item.city}, ${item.region.region} ${item.postcode}, ${item.country_id}`
        }
      })
    },
    async next() {
      if (this.isNewBillingAddress) {
        this.billingInformation.company = this.addressFromCustomerAddresses.hasOwnProperty('company') ? this.addressFromCustomerAddresses.company : ''
        this.billingInformation.streetAddress = this.addressFromCustomerAddresses.street[0]
        this.billingInformation.apartmentNumber = this.addressFromCustomerAddresses.hasOwnProperty('company') ? this.addressFromCustomerAddresses.street[1] : ''
        this.billingInformation.city = this.addressFromCustomerAddresses.city
        this.billingInformation.region_id = this.addressFromCustomerAddresses.region.region_id ? this.addressFromCustomerAddresses.region.region_id : ''
        this.billingInformation.country = this.addressFromCustomerAddresses.country_id
        this.billingInformation.fax = this.addressFromCustomerAddresses.hasOwnProperty('fax') ? this.addressFromCustomerAddresses.fax : ''
        this.billingInformation.state = this.addressFromCustomerAddresses.region.region_code
        this.billingInformation.zipCode = this.addressFromCustomerAddresses.postcode
        this.billingInformation.phoneNumber = this.addressFromCustomerAddresses.telephone
      }
      this.$v.billingInformation.$touch()
      if (this.$v.billingInformation.$invalid) {
        const invalidFields = Object.keys(this.$v.billingInformation.$params).filter(fieldName => this.$v.billingInformation[
          fieldName].$invalid)
        if (invalidFields) {
          if (this.isNewBillingAddress === "") {
            if (invalidFields[0] && this.$refs[invalidFields[0]]) {
              this.$refs[invalidFields[0]].$el.scrollIntoView()
            }
          } else {
            this.$store.dispatch('notification/spawnNotification', {
              type: 'error',
              message: i18n.t('Check your address fields. Fields: ') + invalidFields.join(', ') + ' is required.',
              action1: {
                label: i18n.t('OK')
              }
            })
          }
        }
        return
      }

      if (!this.$v.billingInformation.$invalid) {
        await this.sendDataToCheckout()
        await this.$store.dispatch('cart/syncTotals', {
          forceServerSync: true
        })
        this.isProcessed = true
      }
    }
  },
  watch: {
    payment: function (newVal, oldVal) {
      this.billingInformation = Object.assign({}, this.billingInformation, newVal)
    },
    currentState: function (val) {
      const isRestricted = this.states.find((el) => (el.title === val && el.restricted === true))
      if (isRestricted) {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'warning',
          message: i18n.t(
            'We have removed the option to ship to this address as we do not ship to the selected state/region.'
          ),
          action1: {
            label: i18n.t('OK')
          }
        })
        this.shippingRestricted = true
        this.shipThisAddress = false
      } else {
        this.shippingRestricted = false
        this.shipThisAddress = true
      }
    }
  },
  validations: {
    billingInformation: {
      firstName: {
        required
      },
      lastName: {
        required
      },
      streetAddress: {
        required
      },
      city: {
        required
      },
      country: {
        required
      },
      zipCode: {
        required
      },
      state: {
        required
      },
      phoneNumber: {
        required
      }
    }
  }
}

</script>
<style lang="scss" scoped>
$color-error: #e81212;

.address-select-wrapper {
  select {
    border-radius: 0;
    padding-right: 53px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -webkit-border-radius: 0;
    cursor: pointer;
  }
}

.select-icon {
  position: absolute;
  right: 20px;
  top: 16px;
}

</style>
