<template>
  <div class="order-review"
       :class="{ 'active': isActive }">
    <div class="step-title mb-0 cursor-default text-white block font-black text-base leading-none uppercase tracking-xl">
      {{ $t('Order Review') }}
    </div>
    <div id="checkout-step-review"
         class="step a-item border-solid border-grey-1 relative"
         v-show="isActive">
      <div id="checkout-review-table-wrapper" class="clearfix">
        <table class="data-table w-full" id="checkout-review-table">
          <thead>
          <tr>
            <th class="pro_review_item font-normal border-b-2 border-dark border-solid text-left text-h5 leading-none uppercase tracking-md pb-xs">
              {{ $t('Item') }}
            </th>
            <th class="pro_review_price font-normal border-b-2 border-dark border-solid text-h5 leading-none uppercase tracking-md pb-xs text-right">
              {{ $t('Price') }}
            </th>
            <th class="pro_review_qty font-normal border-b-2 border-dark border-solid text-h5 leading-none uppercase tracking-md pb-xs text-right">
              {{ $t('Qty') }}
            </th>
            <th class="pro_review_subtotal font-normal border-b-2 border-dark border-solid text-h5 leading-none uppercase tracking-md pb-xs text-right">
              {{ $t('Subtotal') }}
            </th>
          </tr>
          </thead>

          <tbody v-if="groupedProducts[name].length" v-for="(group, name) in groupedProducts" :key="name">
            <tr class="pro_review_brandname"
                v-if="group.length">
              <td class="font-bold text-average leading-none tracking-average uppercase py-sm">
                {{ groupedProductsNames[name] || name }}
              </td>
            </tr>
            <template v-for="product in group">
              <order-review-product :product="product" :key="product.sku"/>
              <order-review-product-loyalty :product="product" :key="product.sku + 'loyalty'"/>
            </template>
            <tr class="pro_review_brand_subtotal"
                v-if="group.length">
              <td colspan="2" class="text-base font-bold leading-none tracking-normal text-black pb-sm pt-xs text-right">
                {{ (groupedProductsNames[name] || 'Unclassified') + ' ' + $t('Subtotal') }}
              </td>
              <td colspan="2" class="text-base font-bold leading-none tracking-normal text-black pb-sm pt-xs pl-sm text-right">
                {{ brandSubtotal[name] | price }}
              </td>
            </tr>
          </tbody>

          <tfoot>
          <tr>
            <td class="table-subtotal pt-xs border-t-2 border-dark border-solid text-base leading-none tracking-xs text-right" colspan="3">
              <div>{{ $t('Subtotal') }}</div>
              <small class="text-average text-grey-2">Points are not included in subtotal.</small>
            </td>
            <td class="pt-xs border-t-2 border-dark border-solid text-heading-page leading-h3 tracking-xs text-right font-serif italic">
              {{ brandsSubtotal | price }}
            </td>
          </tr>
          <tr>
            <td class="table-subtotal pt-xs border-t-2 border-dark border-solid text-base leading-none tracking-xs text-right" colspan="3">
              <div>{{ $t('Points Discount') }}</div>
            </td>
            <td class="pt-xs border-t-2 border-dark border-solid text-heading-page leading-h3 tracking-xs text-right font-serif italic">
              {{ getRewardDiscount | price }}
            </td>
          </tr>
          <tr v-if="discountTotal && discountTotal.value">
            <td class="table-subtotal pt-xs text-base leading-none tracking-xs text-right" colspan="3">
              <div>{{ $t(discountTotal.title) }}</div>
            </td>
            <td class="pt-xs text-heading-page leading-h3 tracking-xs text-right font-serif italic">
              {{ discountTotal.value | price }}
            </td>
          </tr>
          <tr v-for="(method, key) in shippingMethodsInfo.methods"
              :key="key">
            <td
              class="table-subtotal pt-xs text-base leading-none tracking-xs text-right"
              colspan="3">
              {{ $t(`Shipping &amp; Handling (FedEx - ${method.method_title})`) }}
            </td>
            <td class="pt-xs text-heading-page leading-h3 tracking-xs text-right font-serif italic">
              {{ method.price_incl_tax | price }}
            </td>
          </tr>
          <tr>
            <td class="table-subtotal pt-xs text-base leading-none tracking-xs text-right" colspan="3">
              {{ $t(grandTotal.title) }}
            </td>
            <td class="pt-xs text-heading-page leading-h3 tracking-xs text-right font-serif italic">
              {{ getGrandTotal | price }}
            </td>
          </tr>
          </tfoot>
        </table>
      </div>
      <button-full
        class="checkout-button relative w-full px-5 md:w-1/2 lg:w-1/4"
        @click.native="placeOrder"
        data-testid="orderReviewSubmit"
      >
        <span>{{ $t('Place order') }}</span>
        <i class="ss-gizmo checkout-icon ss-right absolute text-h5"></i>
      </button-full>
    </div>
  </div>
</template>

<script>
import NoSSR from 'vue-no-ssr'
import { mapGetters } from 'vuex'
import i18n from '@vue-storefront/i18n'
import Cart from 'theme/mixins/pages/Cart'
import ButtonFull from 'theme/components/theme/ButtonFull'
import { OrderReview } from '@vue-storefront/core/modules/checkout/components/OrderReview'
import SearchQuery from '@vue-storefront/core/lib/search/searchQuery'
import ValidationError from 'theme/components/core/ValidationError'
import ProductPrice from 'theme/components/theme/ProductPrice'
import TotalPointsPrice from 'theme/components/theme/blocks/Checkout/TotalPointsPrice'
import RedeemPointsPrice from './RedeemPointsPrice'
import PriceRules from 'theme/mixins/blocks/PriceRules'
import OrderReviewProduct from "theme/components/core/blocks/Checkout/OrderReviewProduct";
import OrderReviewProductLoyalty from "theme/components/core/blocks/Checkout/OrderReviewProductLoyalty";

export default {
  name: 'OrderReview',
  components: {
    OrderReviewProductLoyalty,
    OrderReviewProduct,
    'no-ssr': NoSSR,
    ButtonFull,
    ValidationError,
    ProductPrice,
    TotalPointsPrice,
    RedeemPointsPrice
  },
  mixins: [
    OrderReview,
    Cart,
    PriceRules
  ],
  data () {
    return {
      userBonusValue: 100,
      isProcessed: false,
      userBonusToPay: 20,
      shippingMethodsInfo: {},
      groupedProductsNames: {
        rco: 'R+Co',
        rco_bleu: 'R+Co Bleu',
        rco_color: 'R+Color',
        v76: 'V76 by Vaughn',
        sc: 'Smith & Cult'
      }
    }
  },
  beforeMount () {
    this.shippingMethodsInfo = this.$store.state.shipping
  },
  computed: {
    ...mapGetters({
      loyaltyCart: 'cart/getLoyaltyCartItems'
    }),
    getProductsSkus () {
      let skus = []

      this.productsInCart.forEach(product => {
        skus.push(product.sku)
      })

      return skus
    },
    brandSubtotal () {
      let brandProducts = this.groupedProducts
      let brandSubtotal = {}

      for (let key in brandProducts) {
        let products = brandProducts[key]
        brandSubtotal[key] = 0

        products.forEach(product => {
          // if (this.inLoyaltyCart(product)) return

          let rulePrice = this.getRulePrice(product)

          if (rulePrice) {
            brandSubtotal[key] += rulePrice * (product.qty - this.getPointsQty(product))
          } else {
            brandSubtotal[key] += product.price * (product.qty - this.getPointsQty(product))
          }
        })
      }

      return brandSubtotal
    },
    brandsTotal () {
      let subtotals = this.brandSubtotal
      let total = 0

      for (let key in subtotals) {
        total += subtotals[key]
      }

      return total
    },
    totals () {
      return this.$store.getters['cart/getTotals']
    },
    discountTotal () {
      for (let i = 0; i < this.totals.length; i++) {
        const segment = this.totals[i]

        if (segment.code === 'discount') {
          return segment
        }
      }
      return null
    },
    grandTotal () {
      for (let i = 0; i < this.totals.length; i++) {
        const segment = this.totals[i]

        if (segment.code === 'grand_total') {
          return segment
        }
      }
      return null
    },
    getSubtotal () {
      let subtotal = 0

      this.productsInCart.forEach(product => {
        let rulePrice = this.getRulePrice(product)

        if (rulePrice) {
          subtotal += rulePrice * product.qty
        } else {
          subtotal += product.price * product.qty
        }
      })

      return subtotal
    },
    getRewardDiscount () {
      let discount = this.totals.filter(total => total.code === 'reward_discount')

      return discount.length ? discount[0]['value'] : 0
    },
    brandsSubtotal () {
      return this.getRewardDiscount ? this.getSubtotal + this.getRewardDiscount : this.getSubtotal
    },
    getGrandTotal () {
      let shippingPrice = this.totals.find(total => total.code === 'shipping') || { value: 0 }
      let taxes = this.totals.find(total => total.code === 'tax') || { value: 0 }
      let discount = this.totals.find(total => total.code === 'discount') || { value: 0 }
      let rewardDiscount = this.totals.find(total => total.code === 'reward_discount') || { value: 0 }

      return this.getSubtotal + shippingPrice.value + taxes.value + discount.value + rewardDiscount.value
    }
  },
  methods: {
    getPointsQty (product) {
      return ((product.product_option || {}).extension_attributes || {}).qty_using_points || 0
    },
    inLoyaltyCart (product) {
      let items = this.loyaltyCart || []
      return !!items.filter(item => item.sku === product.sku).length
    },
    onSuccess () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message: i18n.t('You are logged in!'),
        action1: { label: i18n.t('OK') }
      })
    },
    onFailure (result) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: i18n.t(result.result),
        action1: { label: i18n.t('OK') }
      })
    },
    back () {
      this.$bus.$emit('checkout-set-active-section', 'payment')
    },
    async productsInCartList () {
      let searchProductQuery = new SearchQuery()

      searchProductQuery = searchProductQuery.applyFilter({
        key: 'sku',
        value: {'in': this.getProductsSkus}
      })

      const res = await this.$store.dispatch('product/list', {
        query: searchProductQuery,
        sort: 'position:desc',
        size: 1000
      })

      return res ? res.items : []
    },
    async checkStockStatuses () {
      const products = await this.productsInCartList()
      return products.filter(product => !product.stock.is_in_stock)
    },
    async removeFromCart (products) {
      await products.forEach(product => {
        this.$store.dispatch('cart/removeItem', { product: product })
      })
      this.$router.push({ name: 'cart' })
    },
    async placeOrder () {
      let outOfStockProducts = await this.checkStockStatuses()
      if (outOfStockProducts.length) {
        let productsNames = []
        outOfStockProducts.forEach(product => {
          productsNames.push(product.name)
        })
        await this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: i18n.t(`Sorry, but ${productsNames.join(', ')} ${productsNames.length > 1 ? 'products are' : 'product is'} out of stock and will be removed from the cart`),
          action2: {
            label: i18n.t('OK'),
            action: () => {
              this.removeFromCart(outOfStockProducts)
            }
          }
        })
        return
      }
      this.$bus.$emit('checkout-before-placeOrder')
      await this.$bus.$emit('checkout-do-placeOrder')

      this.isProcessed = true
    }
  }
}
</script>

<style lang="scss">
  .order-review {
    #checkout-review-table {
      border-spacing: 0 0.5em !important;
      border-collapse: separate !important;
    }

    &__price {
      .prices {
        span {
          @apply text-base;
        }
      }
    }
  }
</style>
