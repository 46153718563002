<template>
  <no-ssr>
    <div>
      <section id="checkout" class="relative" v-show="!isThankYouPage">
        <div class="pro_checkout_container relative w-full">
          <div class="pro_checkout_header font-black text-h2 leading-none text-dark font-serif">
            {{ $t('Checkout') }}
          </div>
          <div
            v-if="!hasLocations"
            class="px-6 py-4 mb-4 text-base text-black bg-warning rounded-lg"
            role="alert">
            <span class="font-bold">Account Issue:</span>
            Your account does not have locations assigned, please contact your account consultant for account setup.
          </div>
          <div
            v-if="!hasAddresses"
            class="px-6 py-4 mb-4 text-base text-black bg-warning rounded-lg"
            role="alert">
            <span class="font-bold">Account Issue:</span>
            Your account does not have addresses assigned, please contact your account consultant for account setup.
          </div>
          <div class="opc">
            <shipping
              id="shipping"
              class="section allow"
              :is-active="activeSection.shipping"
            />
            <shipping-method
              id="shippingMethod"
              class="section allow"
              :is-active="activeSection.shippingMethod"
            />
            <payment
              v-if="this.$store.state.cart.cartItems.length > 0"
              id="payment"
              class="section allow"
              :is-active="activeSection.payment"
            />
            <order-review
              id="orderReview"
              class="section allow"
              :is-active="activeSection.orderReview"
            />
          </div>
        </div>
        <aside class="pro_checkout_side hidden h-full lg:block" id="affix" :class="{ affix: affix }">
          <div class="pro_checkout_scroller pro_scroller h-full">
            <div class="viewport h-full">
              <perfect-scrollbar>
                <summary-sidebar />
              </perfect-scrollbar>
            </div>
          </div>
          <div class="pro_checkout_sttl fixed z-2 bg-grey-7">
            <div class="z-2 relative">
              <div class="totals flex justify-between items-center px-7">
                <span class="uppercase text-h6 leading-none tracking-md text-dark">{{ $t('Subtotal:') }}</span>
                <span class="italic font-serif heading-page leading-none tracking-thick">
                {{ brandsSubtotal | price }}
              </span>
              </div>
            </div>
          </div>
        </aside>
      </section>
      <thank-you-page v-if="isThankYouPage" />
    </div>
  </no-ssr>
</template>

<script>
import { mapState } from 'vuex'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import Checkout from 'theme/mixins/pages/Checkout'
import BillingInformation from '../components/core/blocks/Checkout/BillingInformation'
import Shipping from 'theme/components/core/blocks/Checkout/Shipping'
import ShippingMethod from 'theme/components/core/blocks/Checkout/ShippingMethod'
import Payment from 'theme/components/core/blocks/Checkout/Payment'
import OrderReview from 'theme/components/core/blocks/Checkout/OrderReview'
import SummarySidebar from 'theme/components/theme/blocks/Checkout/SummarySidebar'
import ThankYouPage from 'theme/components/core/blocks/Checkout/ThankYouPage'
import PriceRules from 'theme/mixins/blocks/PriceRules'
import NoSSR from 'vue-no-ssr'
import { isServer } from '@vue-storefront/core/helpers'
import RootState from "@vue-storefront/core/types/RootState";

export default {
  name: 'Checkout',
  components: {
    PerfectScrollbar,
    BillingInformation,
    Shipping,
    ShippingMethod,
    Payment,
    OrderReview,
    SummarySidebar,
    ThankYouPage,
    'no-ssr': NoSSR
  },
  mixins: [
    Checkout,
    PriceRules
  ],
  data () {
    return {
      affix: false,
      tabletPoint: 768
    }
  },
  async beforeMount () {
    await this.$store.dispatch('user/me', { refresh: isServer || navigator.onLine, useCache: true }, { root: true }) // this will load user cart
    this.$store.dispatch('user/getOrdersHistory', { refresh: isServer || navigator.onLine, useCache: false }, { root: true })
    await this.$store.dispatch('storedCards/getStoredCards')
    await this.$store.dispatch('cart/cartPull', { update: false })
  },
  mounted () {
    // setup billing information
    let location = (this.location || {}).addressId

    // billing address get with fallback
    let addressFromCustomerAddresses = this.customerAddresses.find(item => item.id === location) ||
      this.customerAddresses.find(x => x !== undefined) || false

    this.billingInformation.firstName = this.currentUser.firstname
    this.billingInformation.lastName = this.currentUser.lastname
    this.billingInformation.emailAddress = this.currentUser.email

    if (addressFromCustomerAddresses) {
      this.billingInformation.company = addressFromCustomerAddresses.hasOwnProperty('company') ? addressFromCustomerAddresses.company : ''
      this.billingInformation.streetAddress = addressFromCustomerAddresses.street[0]
      this.billingInformation.apartmentNumber = addressFromCustomerAddresses.hasOwnProperty('company') ? addressFromCustomerAddresses.street[1] : ''
      this.billingInformation.city = addressFromCustomerAddresses.city
      this.billingInformation.region_id = addressFromCustomerAddresses.region.region_id ? addressFromCustomerAddresses.region.region_id : ''
      this.billingInformation.country = addressFromCustomerAddresses.country_id
      this.billingInformation.fax = addressFromCustomerAddresses.hasOwnProperty('fax') ? addressFromCustomerAddresses.fax : ''
      this.billingInformation.state = addressFromCustomerAddresses.region.region_code
      this.billingInformation.zipCode = addressFromCustomerAddresses.postcode
      this.billingInformation.phoneNumber = addressFromCustomerAddresses.telephone

      this.$bus.$emit('checkout-after-billingInformation', this.billingInformation, true)
    }

    !isServer && window.addEventListener('scroll', this.scrolling)

    this.$bus.$emit('checkout-set-active-section', 'shipping')
  },
  computed: {
    ...mapState({
      productsInCart: state => state.cart.cartItems,
      currentUser: state => state.user.current
    }),
    hasAddresses () {
      return (this.customerAddresses || []).length
    },
    hasLocations () {
      return (((this.currentUser || {}).extension_attributes || {}).locations_data || []).length
    },
    totals () {
      return this.$store.getters['cart/getTotals']
    },
    getSubtotal () {
      let subtotal = 0

      this.productsInCart.forEach(product => {
        let rulePrice = this.getRulePrice(product)

        if (rulePrice) {
          subtotal += rulePrice * product.qty
        } else {
          subtotal += product.price * product.qty
        }
      })

      return subtotal
    },
    getRewardDiscount () {
      let discount = this.totals.filter(total => total.code === 'reward_discount')

      return discount.length ? discount[0]['value'] : 0
    },
    brandsSubtotal () {
      return this.getRewardDiscount ? this.getSubtotal + this.getRewardDiscount : this.getSubtotal
    }
  },
  methods: {
    scrolling () {
      if (document.documentElement.clientWidth < this.tabletPoint) return

      this.isFixed()
    },
    isFixed () {
      let $affixItem = document.getElementById('affix')

      if (!$affixItem) return

      if ($affixItem.offsetTop - window.scrollY <= -100) {
        this.affix = true
        document.getElementById('affix').style.top = ''
      } else {
        this.affix = false
        document.getElementById('affix').style.top = ''
      }
    },
    placeOrder () {
      this.$router.push({ name: 'static-checkout-success' })
    },
    notifyEmptyCart () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'warning',
        message: this.$t('Shopping cart is empty. Please add some products before entering Checkout'),
        action1: { label: this.$t('OK') }
      })
    },
    notifyOutStock (chp) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: chp.name + this.$t(' is out of the stock!'),
        action1: { label: this.$t('OK') }
      })
    },
    notifyNotAvailable () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: this.$t('Some of the ordered products are not available!'),
        action1: { label: this.$t('OK') }
      })
    },
    notifyStockCheck () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'warning',
        message: this.$t('Stock check in progress, please wait while available stock quantities are checked'),
        action1: { label: this.$t('OK') }
      })
    },
    notifyNoConnection () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'warning',
        message: this.$t('There is no Internet connection. You can still place your order. We will notify you if any of ordered products is not available because we cannot check it right now.'),
        action1: { label: this.$t('OK') }
      })
    }
  },
  beforeDestroy () {
    !isServer && window.removeEventListener('scroll', this.scrolling)
  }
}
</script>

<style src='vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'/>
<style lang="scss" scoped>
  .pro_checkout_container {
    @apply p-7;

    @screen lg {
      margin: 0 auto;
      max-width: 1600px;
      padding: 0 60px 0 360px;
      min-height: 100vh;
    }

    @media screen and (min-width: 1900px) {
      width: 1180px;
      padding: 0;
    }

    .pro_checkout_header {
      padding: 64px 0 35px 0;
    }

    .opc {
      padding-bottom: 44px;
    }
  }
</style>
<style lang="scss">
  $sidebar-width: 300px;

  .pro_checkout_container {
    .step-title {
      @apply bg-grey-1;
      padding: 13px 0 12px 25px;
      pointer-events: none;
      transition: background-color .5s ease, color .5s ease;
    }

    .step {
      padding: 15px 25px 25px 25px;
      border-width: 0 1px 1px;
      margin-bottom: 25px;
    }

    .section {
      @apply mb-sm;

      &.processed {
        .step-title {
          @apply bg-black cursor-pointer;
          pointer-events: all;

          &:hover {
            @apply bg-brown-2;
          }
        }
      }

      &.active {
        .step {
          @apply mb-25px block;
        }

        .step-title {
          @apply bg-black;
        }
      }
    }

    .checkout-icon {
      line-height: 62px;
      right: 20px;
      top: 1px;
    }

    .back-icon {
      line-height: 62px;
      left: 20px;
      top: 1px;
    }

    .checkout-button {
      @apply pl-5 pr-5 text-left;
    }

    .back-button {
      @apply pl-5 pr-5 text-right;
      padding-bottom: 17px;
      padding-top: 17px;
      width: 115px;
    }

    .payment-switcher {
      transition: color .5s ease;
    }

    .exp-date-year {
      margin-top: 26px;
    }

    .data-table {
      .pro_review_price {
        width: 130px;
      }

      .pro_review_qty {
        width: 65px;
      }

      .pro_review_subtotal {
        width: 90px;
      }

      .table-subtotal {
        padding-right: 65px;
      }
    }
  }

  .ps {
    height: 100%;
  }

  .viewport {
    width: 300px;
  }

  .pro_checkout_side {
    background-image: url('/assets/pattern-tile.png');
    background-repeat: repeat;
    background-position: left top;
    left: 0;
    padding-bottom: 70px;
    top: 0;
    width: 300px;
    height: 100%;
    position: absolute;
    z-index: 1;

    &.affix {
      position: fixed;
    }
  }

  .pro_checkout_sttl {
    width: $sidebar-width;
    height: 70px;
    bottom: 0;
    left: 0;

    &:before {
      @apply absolute rounded-100;
      content: '';
      width: 90%;
      height: 10px;
      top: 0;
      left: 5%;
      z-index: 0;
      box-shadow: 0 0 9px rgba(0, 0, 0, .65);
      border-radius: 100%;
      background-clip: padding-box;
    }

    &:after {
      @apply w-full h-full absolute z-1 bg-grey-7;
      content: '';
      top: 0;
      left: 0;
    }
  }

  .totals {
    padding-bottom: 26px;
    padding-top: 26px;
  }
</style>
