<template>
  <div>
    <div v-if="!isTotal">
      <span v-if="!rulePrice">
        {{ product.point_redemption_price.toFixed(2) }}
      </span>
      <span v-else>
        {{ rulePrice }}
      </span>
      <span>
        {{ $t('Pts') }}
      </span>
    </div>
    <div v-else>
      <span v-if="!rulePrice">
        {{ (product.point_redemption_price * (product.qty ? product.qty : this.incrementStep)).toFixed(2) }}
      </span>
      <span v-else>
        {{ (rulePrice * (product.qty ? product.qty : this.incrementStep)).toFixed(2) }}
      </span>
      <span>
        {{ $t('Pts') }}
      </span>
    </div>
  </div>
</template>

<script>
  import PriceRules from 'theme/mixins/blocks/PriceRules'
  import { getProductQtyIncrement } from 'theme/helpers/getProductQtyIncrement'

  export default {
    name: 'RedeemPointsPrice',
    mixins: [
      PriceRules
    ],
    data () {
      return {
        incrementStep: 1,
        rulePrice: null
      }
    },
    props: {
      product: {
        type: Object,
        required: true
      },
      isTotal: {
        type: Boolean,
        default: false
      }
    },
    beforeMount () {
      this.incrementStep = getProductQtyIncrement(this.product)

      this.rulePrice = this.getRulePrice(this.product)
    }
  }
</script>
