<template>
  <div :class="{'active': isActive, 'processed': isProcessed && !isActive}">
    <div
      class="step-title mb-0 cursor-default text-white block font-black text-base leading-none uppercase tracking-xl"
      @click="goToStep('shippingMethod')"
    >
      {{ $t('Shipping Method') }}
    </div>
    <div id="checkout-step-shipping_method"
         class="step a-item border-solid border-grey-1 relative"
         v-if="isActive">
      <div class="font-bold text-md leading-none tracking-thick uppercase pb-sm">
        {{ $t('LBP PRO') }}
      </div>
      <base-radiobutton
        id="radio-shipping-method1"
        :value="true"
        v-for="(method, key) in shippingMethods"
        :key="key"
      >
        <span class="text-base leading-none tracking-xs text-dark pl-md">
          {{ $t(`${method.method_title}`) }}
        </span>
        <span class="text-heading-page leading-h5 font-serif italic">
          {{ method.price_incl_tax | price }}
        </span>
      </base-radiobutton>
      <div class="text-base w-full mt-xs lg:w-2/5">
        {{ $t('Please note that each brand ships separately, your credit card will be charged for a portion of the above total shipping cost by each brand.') }}
      </div>
      <div class="flex mt-lg mb-25px">
        <button-full
          class="back-button relative px-5 w-1/12 mr-sm"
          @click.native="toShippingInfo"
        >
          <i class="ss-gizmo back-icon ss-left absolute text-h5"></i>
          <span>
            {{ $t('Back') }}
          </span>
        </button-full>
        <button-full
          class="checkout-button relative w-2/3 px-5 lg:w-1/4"
          @click.native="next()"
        >
          <span>
            {{ $t('Continue') }}
          </span>
          <i class="ss-gizmo checkout-icon ss-right absolute text-h5"></i>
        </button-full>
      </div>
    </div>
  </div>
</template>

<script>
import { Shipping } from '@vue-storefront/core/modules/checkout/components/Shipping'
import BaseRadiobutton from 'theme/components/core/blocks/Form/BaseRadiobutton'
import GoToCheckoutStep from 'theme/mixins/blocks/GoToCheckoutStep'
import ButtonFull from 'theme/components/theme/ButtonFull'

export default {
  name: 'ShippingMethod',
  components: {
    BaseRadiobutton,
    ButtonFull
  },
  mixins: [
    Shipping,
    GoToCheckoutStep
  ],
  props: {
    isActive: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      isProcessed: false
    }
  },
  methods: {
    toShippingInfo () {
      this.$bus.$emit('checkout-set-active-section', 'shipping')
    },
    next () {
      let currentShippingMethod = this.getCurrentShippingMethod()

      if (currentShippingMethod) {
        this.shipping = Object.assign(this.shipping, {shippingCarrier: currentShippingMethod.carrier_code})

        this.$bus.$emit('checkout-after-shippingMethodChanged', {
          country: this.shipping.country,
          method_code: this.shipping.method_code,
          carrier_code: this.shipping.carrier_code,
          payment_method: 'stored_card'
        })
      }

      this.isFilled = true
      this.$bus.$emit('checkout-set-active-section', 'payment')
      this.isProcessed = true
    }
  }
}
</script>

<style lang="scss">
  .icon-truck {
    width: 32px;
    height: 32px;
  }
</style>
