<template>
  <tr v-if="isStandardItem">
    <td class="text-left text-base leading-h4 tracking-xs">
      {{ product.name | htmlDecode }}
      <span class="inline-block text-average text-grey-23 pl-xs" v-if="product.weight">
        {{ `${product.weight} Oz` }}
      </span>
      <span class="inline-block text-average text-grey-23 pl-xs" v-if="configurableProductInfo">
        {{ configurableProductInfo.value | stripHTML }}
      </span>
    </td>
    <td class="points text-right text-md leading-h4 tracking-xs">
        <span v-if="!getRulePrice(product)">
          <product-price
            :product="product"
          />
        </span>
      <span v-else class="">
          {{ getRulePrice(product) | price }}
        </span>
    </td>
    <td class="text-right text-base leading-h4 tracking-xs">
      <span class="block">{{ getQuantity }}</span>
    </td>
    <td class="text-right text-base leading-h4 tracking-xs pl-sm">
      <span>
        <span v-if="!getRulePrice(product)">
          <product-price :product="product" :is-total="true"/>
        </span>
        <span v-else>
          {{ getRulePrice(product) * getQuantity | price }}
        </span>
      </span>
    </td>
  </tr>
</template>

<script>
import ProductPrice from 'theme/components/theme/ProductPrice'
import Product from 'theme/mixins/blocks/Product'

export default {
  name: 'OrderReviewProduct',
  components: {
    ProductPrice
  },
  mixins: [Product],
  props: {
    product: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
