<template>
  <div :class="{'active': isActive, 'processed': isProcessed && !isActive}">
    <div
      class="step-title mb-0 cursor-default text-white block font-black text-base leading-none uppercase tracking-xl"
      @click="goToStep('shipping')"
    >
      {{ $t('Shipping Information') }}
    </div>
    <div id="checkout-step-shipping"
         class="step a-item border-solid border-grey-1 relative"
         v-if="isActive">

      <div class="bg-error font-bold border border-error text-white px-4 py-3 relative" role="alert" v-if="locationUnavailable">
        <span class="block sm:inline">The location selected does not match the address tied to your account,
          please reach out to your account consultant for corrections.</span>
      </div>

      <address class="text-heading-page leading-h5 tracking-xs text-dark not-italic" v-if="shipping.street">
        <span class="block">
          {{ shipping.firstname }}
        </span>
        <span class="block">
          {{ shipping.lastname }}
        </span>
        <span class="block" v-if="shipping.street">
          {{ `${shipping.street[0] ? shipping.street[0] : ''}, ${shipping.street[1] ? shipping.street[1] : ''}` }}
        </span>
        <span class="block">
          {{ `${shipping.city}, ${(shipping.region || {}).region}, ${shipping.postcode}` }}
        </span>
        <span class="block">
          {{ shipping.country_id }}
        </span>
        <span class="block">
          {{ `T: ${shipping.telephone}` }}
        </span>
      </address>
      <div class="flex mt-lg mb-25px">
        <button-full class="back-button relative px-5 w-1/12 mr-sm"
                     @click.native="returnToCart">
          <i class="ss-gizmo back-icon ss-left absolute text-h5"></i>
          <span>
            {{ $t('Back') }}
          </span>
        </button-full>
        <button-full class="checkout-button relative w-2/3 px-5 lg:w-1/4"
                     @click.native="next">
          <span>
            {{ $t('Continue') }}
          </span>
          <i class="ss-gizmo checkout-icon ss-right absolute text-h5"></i>
        </button-full>
      </div>
    </div>
  </div>
</template>

<script>
import { Shipping } from '@vue-storefront/core/modules/checkout/components/Shipping'
import ButtonFull from 'theme/components/theme/ButtonFull'
import GoToCheckoutStep from 'theme/mixins/blocks/GoToCheckoutStep'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'Shipping',
  components: {
    ButtonFull
  },
  mixins: [
    Shipping,
    GoToCheckoutStep
  ],
  data () {
    return {
      isProcessed: false,
      shippingData: {},
      locationUnavailable: false
    }
  },
  beforeMount () {
    let addressId = (this.location || {}).addressId
    let address = this.currentUser.addresses.find(address => address.id == addressId)

    if (!address) {
      this.locationUnavailable = true
      return
    }

    this.shippingData = {
      state: address.region.region,
      region_id: address.region.region_id,
      country: address.country_id,
      streetAddress: address.street[0],
      apartmentNumber: address.street[1] || '',
      company: address.company || '',
      phoneNumber: address.telephone,
      fax: address.fax || '',
      zipCode: address.postcode,
      city: address.city,
      firstName: address.firstname,
      lastName: address.lastname,
      emailAddress: address.email || '',
      region_code: address.region.region_code || ''
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.user.current
    }),
    ...mapGetters({
      location: 'storedCards/getLocation'
    })
  },
  methods: {
    async next () {
      this.$bus.$emit('checkout-after-shippingDetails', Object.assign(this.shipping, this.$store.state.checkout.shippingDetails, this.shippingData), this.shippingData)
      this.isFilled = true
      await this.$store.dispatch('cart/syncTotals', { forceServerSync: true })
      this.isProcessed = true
    },
    toBillingInfo () {
      this.$bus.$emit('checkout-set-active-section', 'billingInformation')
    },
    returnToCart () {
      this.$router.push({ name: 'cart' })
    }
  }
}
</script>
